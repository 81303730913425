import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Stripe } from 'stripe';
import { CognitoUser } from '@aws-amplify/auth';
import { addCustomAnalyticsUserAttributes, AnalyticsEvents, track } from '../../../src/utils/AnalyticsUtils';
import { UserAttributesType } from '../../onboarding/utils/types';
import { LimitationType, LimitationTypeInput, UpdateUserInput, User } from '../../../src/API';
import dataOperations from '../../../lib/UserSpace';
import AmplifyUtils from '../../../src/utils/AmplifyUtils';
import { defaultPriceCurrency, enterpriseProductData, RedirectUrlType, stripe, StripeCheckoutMode, StripeSubscriptionWithPlan } from '../../subscription/utils/types';
import { SessionStorageItemType } from '../types';
import getSymbolFromCurrency from 'currency-symbol-map'

type ProductsForUpdate = {
    product: string;
    prices: string[];
};

type priceCycleType = {
    priceId: string;
    price: number;
    customProduct: number;
    features: (string | null)[];
};

export enum stripeCustomerPortalFlowType {
    PAYMENT_UPDATE = 'payment_method_update',
    SUBSCRIPTION_CANCEL = 'subscription_cancel',
    SUBSCRIPTION_UPDATE = 'subscription_update',
    SUBSCRIPTION_UPDATE_CONFIRM = 'subscription_update_confirm',
}

export interface StripeProductWithAdditionaldata extends Stripe.Product {
    pricesData: Stripe.Price[];
    priceMonthly: priceCycleType;
    priceYearly: priceCycleType;
    features: (string | null)[];
}
export interface SubscriptionState {
    stripeCustomer: Stripe.Customer;
    customerSubscriptionsHistory: Stripe.Subscription[];
    customerSubscription: StripeSubscriptionWithPlan;
    subscriptionProduct: Stripe.Product;
    products: StripeProductWithAdditionaldata[];
    isCustomerFetching: boolean;
    isCustomerSubscriptionFetched: boolean;
    generatedStoriesAmount: number;
    generatedReimaginesAmount: number;
    createdProjectsAmount: number;
    createdProductsAmount: number;
    createdStandardProductsAmount: number;
    downloadedImages: number;
    downloadedVideos: number;
    generatedPacks: number;
    limitation: LimitationTypeInput;
    isCustomerProductsAmountUpdating: boolean;
    isLimitationSet: boolean;
    startingPriceForImmersiveProductToDisplay: Stripe.Price | null

    fetchStripeCustomer: (props: { stripeId: string; email: string; user: CognitoUser; userOnboarding: UpdateUserInput }) => Promise<void>;
    fetchProducts: (currency?: string) => Promise<void>;

    redirectToStripeCheckout: (props: {
        mode: StripeCheckoutMode;
        priceId: string | string[];
        path: string;
        cognitoUserId: string;
        email: string;
        customer: string;
        products?: number;
        cancelUrl: string;
        subscriptionType?: string;
        additionalMetadata?: any;
        currency?: string;
    }) => Promise<string | undefined | null>;
    redirectToStripeCustomerProtal: (props: {
        path: string;
        stripeCustomerPortalFlowType?: stripeCustomerPortalFlowType;
        productToUpdate?: {
            product: string;
            prices: string[];
        };
    }) => Promise<string | undefined | null>;
    updateStripeCustomer: (props: {
        user: CognitoUser;
        generatedStoriesAmount?: number;
        generatedReimaginesAmount?: number;
        createdProjectsAmount?: number;
        createdProductsAmount?: number;
        createdStandardProductsAmount?: number;
        downloadedImages?: number;
        downloadedVideos?: number;
        generatedPacks?: number;
        userOnboarding?: any;
    }) => Promise<User | undefined>;
    setLimitation: (props: { user: CognitoUser; limitation: LimitationType }) => Promise<void>;
    setCustomerSubscriptionFetched: () => void;
    setSubscriptionCanceledState: (props: { user: CognitoUser; state: boolean }) => void;
}

const defaultValues = {
    stripeCustomer: {} as Stripe.Customer,
    customerSubscriptionsHistory: [],
    customerSubscription: {} as StripeSubscriptionWithPlan,
    subscriptionProduct: {} as Stripe.Product,
    products: [] as StripeProductWithAdditionaldata[],
    isCustomerFetching: true,
    isCustomerSubscriptionFetched: false,
    generatedStoriesAmount: 0,
    generatedReimaginesAmount: 0,
    createdProjectsAmount: 0,
    createdProductsAmount: 0,
    createdStandardProductsAmount: 0,
    downloadedImages: 0,
    downloadedVideos: 0,
    generatedPacks: 0,
    limitation: {
        generatedStoriesInLimitPeriod: 0,
        limitResetDate: JSON.stringify(new Date().valueOf() + 24 * 60 * 60 * 1000 * 7),
        projectLimit: 1,
        storiesLimit: 12,
        storiesLimitPeriod: JSON.stringify(24 * 60 * 60 * 1000 * 7),
        customProducts: 0,
    },
    isCustomerProductsAmountUpdating: false,
    isLimitationSet: false,
    startingPriceForImmersiveProductToDisplay: null,
};

export const defaultLimitation = {
    renewGeneratedStories: 0,
    oneDayInMilliseconds: 24 * 60 * 60 * 1000,
    storiesLimit: 12,
    storiesLimitPeriod: 24 * 60 * 60 * 1000 * 7,
};

const stripeCustomerRetrieveById = async (stripeId: string) => {
    try {
        const stripeCustomer = await stripe.customers.retrieve(stripeId, {
            expand: ['subscriptions', 'subscriptions.data.latest_invoice', 'subscriptions.data.latest_invoice.charge'],
        });
        return stripeCustomer;
    } catch (error) {
        return {};
    }
};

const stripeCustomerRetrieveByEmail = async (email: string) => {
    try {
        const stripeCustomer = await stripe.customers.search({
            query: `email:\'${email}\'`,
            expand: ['data.subscriptions', 'data.subscriptions.data.latest_invoice'],
        });
        return stripeCustomer.data[0];
    } catch (error) {
        return {};
    }
};
const useSubscriptionStore = create<SubscriptionState>()(
    devtools(
        (set, get) => ({
            ...defaultValues,
            fetchStripeCustomer: async (props: { stripeId: string; email: string; user: CognitoUser; userOnboarding: UpdateUserInput }) => {
                try {
                    set(() => ({ isCustomerFetching: true }));
                    if (props.stripeId) {
                        let customer: Stripe.Customer | Stripe.DeletedCustomer | any;
                        let isCustomerFetchedByEmail = false;
                        const customerById = await stripeCustomerRetrieveById(props.stripeId);
                        if (Object.keys(customerById).length) {
                            customer = customerById;
                        } else {
                            const customerByEmail: Stripe.Customer | Stripe.DeletedCustomer | any = await stripeCustomerRetrieveByEmail(
                                props.email,
                            );
                            if (Object.keys(customerByEmail).length) {
                                const updateUserInput: UpdateUserInput = {
                                    owner: props.user.getUsername(),
                                    stripeId: customerByEmail.id,
                                };
                                await dataOperations.updateUserData(updateUserInput);
                            }
                            customer = customerByEmail;
                            isCustomerFetchedByEmail = true;
                        }
                        if (Object.keys(customer).length) {
                            if (!customer.deleted) {
                                const customerSubscriptions: Stripe.ApiList<Stripe.Subscription> | undefined = customer.subscriptions;
                                if (customerSubscriptions?.data.length) {
                                    const customerProduct: Stripe.Product | Stripe.DeletedProduct | string | null =
                                        customerSubscriptions.data[0].items.data[0].plan.product;

                                    const product = await stripe.products.retrieve(customerProduct as string);

                                    const subscriptionPlanPrice =
                                        ((customerSubscriptions.data[0] as StripeSubscriptionWithPlan).plan?.amount as number) / 100;
                                    const subscriptionCurrencySymbol = getSymbolFromCurrency(customerSubscriptions.data[0].currency);
                                    const subscriptionAnalyticsData = {
                                        'Plan Type': product.name,
                                        'Plan Billing Cycle': (customerSubscriptions.data[0] as StripeSubscriptionWithPlan).plan.interval,
                                        Status: customerSubscriptions.data[0].status,
                                        'Trial status': props.userOnboarding.trialSubscriptionStatus
                                            ? props.userOnboarding.trialSubscriptionStatus
                                            : 'NOT_STARTED',
                                        'Start Date': new Date(customerSubscriptions.data[0].current_period_start * 1000).toString(),
                                        'End Date': new Date(customerSubscriptions.data[0].current_period_end * 1000).toString(),
                                        'Payment Method':
                                            (customerSubscriptions.data[0] as StripeSubscriptionWithPlan).latest_invoice?.charge
                                                ?.payment_method_details?.type || 'card',
                                        'Auto Renew': customerSubscriptions.data[0].collection_method === 'charge_automatically',
                                        Price: subscriptionPlanPrice ? `${subscriptionCurrencySymbol}${subscriptionPlanPrice.toFixed(2)}` : `${subscriptionCurrencySymbol}0.00`,
                                        'Actual Price': !subscriptionPlanPrice
                                            ? `${subscriptionCurrencySymbol}0.00`
                                            : !customerSubscriptions.data[0].discount
                                            ? `${subscriptionCurrencySymbol}${subscriptionPlanPrice.toFixed(2)}`
                                            : customerSubscriptions.data[0].discount.coupon.percent_off
                                            ? `${subscriptionCurrencySymbol}${(
                                                  subscriptionPlanPrice -
                                                  subscriptionPlanPrice * (customerSubscriptions.data[0].discount.coupon.percent_off / 100)
                                              ).toFixed(2)}`
                                            : `${subscriptionCurrencySymbol}${subscriptionPlanPrice.toFixed(2)}`,
                                        Discount: customerSubscriptions.data[0].discount
                                            ? `${customerSubscriptions.data[0].discount.coupon.percent_off}%`
                                            : '0%',
                                    };
                                    addCustomAnalyticsUserAttributes(UserAttributesType.SUBSCRIPTION, subscriptionAnalyticsData);
                                    if (isCustomerFetchedByEmail) {
                                        const updateUserInput: UpdateUserInput = {
                                            owner: props.user.getUsername(),
                                            limitation: {
                                                generatedStoriesInLimitPeriod:
                                                    props.userOnboarding.limitation?.generatedStoriesInLimitPeriod,
                                                createdProjectsInLimitPeriod: props.userOnboarding.limitation?.createdProjectsInLimitPeriod,
                                                createdCustomProductsInLimitPeriod:
                                                    props.userOnboarding.limitation?.createdCustomProductsInLimitPeriod,
                                                createdStandardProductsInLimitPeriod:
                                                    props.userOnboarding.limitation?.createdStandardProductsInLimitPeriod,
                                                subscriptionStartDate: JSON.stringify(new Date().valueOf()),
                                                limitResetDate: JSON.stringify(
                                                    new Date().valueOf() +
                                                        24 * 60 * 60 * 1000 * Number(product.metadata.storiesLimitPeriod),
                                                ),
                                                projectLimit: Number(product.metadata.projectLimit),
                                                storiesLimit: Number(product.metadata.storiesLimit),
                                                storiesLimitPeriod: JSON.stringify(
                                                    24 * 60 * 60 * 1000 * Number(product.metadata.storiesLimitPeriod),
                                                ),
                                                customProducts: Number(
                                                    (customerSubscriptions.data[0] as StripeSubscriptionWithPlan).plan.metadata
                                                        .customProduct,
                                                ),
                                                standardProducts: Number(
                                                    (customerSubscriptions.data[0] as StripeSubscriptionWithPlan).plan.metadata
                                                        .standardProduct,
                                                ),
                                            },
                                        };
                                        await dataOperations.updateUserData(updateUserInput);
                                    }
                                    set(() => ({
                                        stripeCustomer: customer,
                                        customerSubscription: customerSubscriptions.data[0] as StripeSubscriptionWithPlan,
                                        subscriptionProduct: product,
                                        isCustomerFetching: false,
                                        isCustomerSubscriptionFetched: true,
                                    }));
                                } else {
                                    const customerSubscriptionsHistory = await stripe.subscriptions.list({
                                        customer: props.stripeId,
                                        status: 'canceled',
                                    });
                                    addCustomAnalyticsUserAttributes(UserAttributesType.SUBSCRIPTION, { 'Plan Type': 'Free' });
                                    if (isCustomerFetchedByEmail) {
                                        const updateUserInput: UpdateUserInput = {
                                            owner: props.user.getUsername(),
                                            limitation: {
                                                generatedStoriesInLimitPeriod:
                                                    props.userOnboarding.limitation?.generatedStoriesInLimitPeriod,
                                                createdProjectsInLimitPeriod: props.userOnboarding.limitation?.createdProjectsInLimitPeriod,
                                                createdCustomProductsInLimitPeriod:
                                                    props.userOnboarding.limitation?.createdCustomProductsInLimitPeriod,
                                                createdStandardProductsInLimitPeriod:
                                                    props.userOnboarding.limitation?.createdStandardProductsInLimitPeriod,
                                                subscriptionStartDate: JSON.stringify(new Date().valueOf()),
                                                limitResetDate: JSON.stringify(new Date().valueOf() + 24 * 60 * 60 * 1000 * 7),
                                                projectLimit: 1,
                                                storiesLimit: 12,
                                                storiesLimitPeriod: JSON.stringify(24 * 60 * 60 * 1000 * 7),
                                                customProducts: 0,
                                                standardProducts: 2,
                                            },
                                        };
                                        await dataOperations.updateUserData(updateUserInput);
                                    }
                                    set(() => ({
                                        stripeCustomer: customer,
                                        customerSubscriptionsHistory: customerSubscriptionsHistory.data,
                                        isCustomerFetching: false,
                                        isCustomerSubscriptionFetched: true,
                                    }));
                                }
                            } else {
                                addCustomAnalyticsUserAttributes(UserAttributesType.SUBSCRIPTION, { 'Plan Type': 'Free' });
                                set(() => ({ isCustomerFetching: false, isCustomerSubscriptionFetched: true }));
                            }
                        } else {
                            addCustomAnalyticsUserAttributes(UserAttributesType.SUBSCRIPTION, { 'Plan Type': 'Free' });
                            set(() => ({ isCustomerFetching: false, isCustomerSubscriptionFetched: true }));
                        }
                    }
                } catch (error) {
                    console.log('Failed to fetch stripe data', error);
                    set(() => ({ isCustomerFetching: false, isCustomerSubscriptionFetched: true }));
                }
            },
            fetchProducts: async (currency?: string) => {
                try {
                    const [allProducts, recurringPrices, startingPriceForImmersiveProductToDisplay] = await Promise.all([
                        stripe.products.list(),
                        stripe.prices.list({
                            type: 'recurring',
                            active: true, // if you also want to filter by active prices
                            expand: ['data.currency_options'],
                            limit: 100,
                        }),
                        stripe.prices.search({
                            query: `active:\'true\' AND currency:\'${currency}\' AND metadata[\'complexityRank\']:\'2\'`,
                        })
                    ]);
                    const acceptableStripeCurrencies = recurringPrices.data.map((price) => price.currency);
                    set({ startingPriceForImmersiveProductToDisplay: startingPriceForImmersiveProductToDisplay.data.length ? startingPriceForImmersiveProductToDisplay.data[0] : null });
                    if (allProducts && recurringPrices) {
                        const stripeProducts = allProducts.data
                            .map((product) => {
                                const productPrices = recurringPrices.data.filter((price) => price.product === product.id && (currency && acceptableStripeCurrencies.includes(currency) ? price.currency === currency : price.currency === defaultPriceCurrency));
                                const productPriceMonthly = productPrices.reduce<priceCycleType>(
                                    (acc, rec) => {
                                        const priceFeatures = Object.keys(rec.metadata)
                                            .map((item) => {
                                                if (item.includes('feature')) {
                                                    return rec.metadata[item];
                                                }
                                                return null;
                                            })
                                            .filter((item) => item !== null);
                                        if (rec.recurring?.interval === 'month' && rec.active) {
                                            if (rec.unit_amount) {
                                                return {
                                                    ...acc,
                                                    priceId: rec.id,
                                                    price: rec.unit_amount / 100,
                                                    customProduct: Number(rec.metadata.customProduct) || 0,
                                                    features: priceFeatures,
                                                };
                                            }
                                            return {
                                                ...acc,
                                                priceId: rec.id,
                                                price: 0,
                                                customProduct: Number(rec.metadata.customProduct) || 0,
                                                features: priceFeatures,
                                            };
                                        }
                                        return acc;
                                    },
                                    {
                                        priceId: '',
                                        price: -1,
                                        customProduct: 0,
                                        features: [],
                                    } as priceCycleType,
                                );
                                const productPriceYearly = productPrices.reduce<priceCycleType>(
                                    (acc, rec) => {
                                        const priceFeatures = Object.keys(rec.metadata)
                                            .map((item) => {
                                                if (item.includes('feature')) {
                                                    return rec.metadata[item];
                                                }
                                                return null;
                                            })
                                            .filter((item) => item !== null);
                                        if (rec.recurring?.interval === 'year' && rec.active) {
                                            if (rec.unit_amount) {
                                                return {
                                                    ...acc,
                                                    priceId: rec.id,
                                                    price: Number((rec.unit_amount / 100 / 12).toFixed(2)),
                                                    customProduct: Number(rec.metadata.customProduct) || 0,
                                                    features: priceFeatures,
                                                };
                                            }
                                            return {
                                                ...acc,
                                                priceId: rec.id,
                                                price: 0,
                                                customProduct: Number(rec.metadata.customProduct) || 0,
                                                features: priceFeatures,
                                            };
                                        }
                                        return acc;
                                    },
                                    {
                                        priceId: '',
                                        price: -1,
                                        customProduct: 0,
                                        features: [],
                                    } as priceCycleType,
                                );
                                const productFeatures = Object.keys(product.metadata)
                                    .map((item) => {
                                        if (item.includes('feature')) {
                                            return product.metadata[item];
                                        }
                                        return null;
                                    })
                                    .filter((item) => item !== null);

                                if (productPriceMonthly.priceId && productPriceYearly.priceId) {
                                    return {
                                        ...product,
                                        pricesData: productPrices,
                                        priceMonthly: productPriceMonthly as priceCycleType,
                                        priceYearly: productPriceYearly as priceCycleType,
                                        features: productFeatures,
                                    };
                                } else {
                                    return null;
                                }
                            })
                            .filter((product): product is StripeProductWithAdditionaldata => product !== null);

                        stripeProducts.sort((a, b) => (a?.priceMonthly?.price ?? 0) - (b?.priceMonthly?.price ?? 0));

                        set(() => ({ products: stripeProducts }));
                    }
                } catch (error) {
                    console.log('Failed to fetch stripe products', error);
                }
            },
            redirectToStripeCheckout: async (props: {
                mode: StripeCheckoutMode;
                priceId: string | string[];
                path: string;
                cognitoUserId: string;
                email: string;
                customer: string;
                products?: number;
                cancelUrl: string;
                subscriptionType?: string;
                additionalMetadata?: any;
                currency?: string;
            }) => {
                try {
                    const stage = AmplifyUtils.getAmplifyEnv();
                    const createdProducts = props.products;
                    const customer = props.customer;
                    const customer_email = props.email;
                    const isCustomerExist = !!customer;
                    const lineItems = Array.isArray(props.priceId) 
                    ? props.priceId.map((item) => {
                        return {
                            price: item,
                            quantity: 1,
                        }
                    })
                    : [{
                        price: props.priceId,
                        quantity: 1,
                    }];
                    const session = await stripe.checkout.sessions.create({
                        line_items: lineItems,
                        mode: props.mode,
                        success_url: props.path,
                        cancel_url: props.cancelUrl,
                        client_reference_id: props.cognitoUserId,
                        allow_promotion_codes: true,
                        metadata: {
                            stage,
                            ...(props.subscriptionType && { subscriptionType: props.subscriptionType }),
                            ...(props.additionalMetadata && { ...props.additionalMetadata }),
                        },
                        ...(props.subscriptionType && {
                            subscription_data: {
                                trial_settings: {
                                    end_behavior: {
                                        missing_payment_method: 'cancel',
                                    },
                                },
                                trial_period_days: 7,
                            },
                        }),
                        ...(customer && { customer }),
                        ...(!isCustomerExist && { customer_email }),
                        ...(props.currency && {  currency: props.currency.toUpperCase() } ),
                    });
                    track(AnalyticsEvents.SUBSCRIPTION_VIEWED_STRIPE_CHECKOUT);
                    return session.url;
                } catch (error) {
                    console.log('Failed to redirect to stripe checkout', error);
                }
            },
            redirectToStripeCustomerProtal: async (props: {
                path: string;
                stripeCustomerPortalFlowType?: stripeCustomerPortalFlowType;
                productToUpdate?: {
                    product: string;
                    prices: string[];
                };
            }) => {
                try {
                    const products = get().products;
                    const subscription = get().customerSubscription;

                    const yearProductsForUpdate = products
                        .map((product) => {
                            if (product.active) {
                                return { product: product.id, prices: [product.priceYearly.priceId] };
                            }
                            return null;
                        })
                        .filter((product) => product);

                    const allProductsForUpdate = products
                        .map((product) => {
                            if (product.active) {
                                return { product: product.id, prices: [product.priceMonthly.priceId, product.priceYearly.priceId] };
                            }
                            return null;
                        })
                        .filter((product) => product);

                    const enableProductsToUpdate =
                        !subscription.cancel_at && subscription.plan.interval === 'year'
                            ? { products: yearProductsForUpdate as ProductsForUpdate[] }
                            : { products: allProductsForUpdate as ProductsForUpdate[] };

                    const session = await stripe.billingPortal.sessions.create({
                        customer: get().stripeCustomer.id,
                        return_url: `${props.path}`,
                        ...(props.stripeCustomerPortalFlowType &&
                            props.stripeCustomerPortalFlowType === stripeCustomerPortalFlowType.SUBSCRIPTION_UPDATE && {
                                flow_data: {
                                    type: stripeCustomerPortalFlowType.SUBSCRIPTION_UPDATE,
                                    subscription_update: {
                                        subscription: subscription.id,
                                    },
                                    after_completion: {
                                        type: 'redirect',
                                        redirect: {
                                            return_url: `${props.path}`,
                                        },
                                    },
                                },
                            }),
                        ...(props.stripeCustomerPortalFlowType &&
                            props.stripeCustomerPortalFlowType === stripeCustomerPortalFlowType.PAYMENT_UPDATE && {
                                flow_data: {
                                    type: stripeCustomerPortalFlowType.PAYMENT_UPDATE,
                                    after_completion: {
                                        type: 'redirect',
                                        redirect: {
                                            return_url: `${props.path}`,
                                        },
                                    },
                                },
                            }),
                    });
                    const configuration = await stripe.billingPortal.configurations.update(session.configuration as string, {
                        features: {
                            subscription_cancel: {
                                cancellation_reason: {
                                    enabled: false,
                                },
                                mode: subscription.status === 'trialing' ? 'immediately' : 'at_period_end',
                            },
                            subscription_update: props.productToUpdate ? { products: [props.productToUpdate] } : enableProductsToUpdate,
                        },
                    });

                    return session.url;
                } catch (error) {
                    console.log('Failed to redirect to stripe customer portal', error);
                }
            },
            updateStripeCustomer: async (props: {
                user: CognitoUser;
                generatedStoriesAmount?: number;
                generatedReimaginesAmount?: number;
                createdProjectsAmount?: number;
                createdProductsAmount?: number;
                createdStandardProductsAmount?: number;
                downloadedImages?: number;
                downloadedVideos?: number;
                generatedPacks?: number;
                userOnboarding?: any;
            }) => {
                const stripeCustomer = await stripeCustomerRetrieveById(props.userOnboarding.stripeId);

                try {
                    const currentLimitation = Object.keys(stripeCustomer).length ? get().limitation : props.userOnboarding.limitation;
                    let updatedLimitationData = {
                        ...currentLimitation,
                        generatedStoriesInLimitPeriod: get().generatedStoriesAmount,
                        createdProjectsInLimitPeriod: get().createdProjectsAmount,
                        createdCustomProductsInLimitPeriod: get().createdProductsAmount,
                        createdStandardProductsInLimitPeriod: get().createdStandardProductsAmount,
                        generatedReimagines: get().generatedReimaginesAmount,
                        downloadedImages: get().downloadedImages,
                        downloadedVideos: get().downloadedVideos,
                        generatedPacks: get().generatedPacks,
                    };
                    if (props.generatedReimaginesAmount) {
                        const newGeneratedReimaginesAmount = get().generatedReimaginesAmount + props.generatedReimaginesAmount;
                        updatedLimitationData = { ...updatedLimitationData, generatedReimagines: newGeneratedReimaginesAmount };
                        set(() => ({ generatedReimaginesAmount: newGeneratedReimaginesAmount }));
                    }
                    if (props.generatedStoriesAmount) {
                        const newGeneratedStoriesAmount = get().generatedStoriesAmount + props.generatedStoriesAmount;
                        updatedLimitationData = { ...updatedLimitationData, generatedStoriesInLimitPeriod: newGeneratedStoriesAmount };
                        set(() => ({ generatedStoriesAmount: newGeneratedStoriesAmount }));
                    }
                    if (props.createdProjectsAmount) {
                        const newCreatedProjectsAmount = get().createdProjectsAmount + props.createdProjectsAmount;
                        updatedLimitationData = { ...updatedLimitationData, createdProjectsInLimitPeriod: newCreatedProjectsAmount };
                        set(() => ({ createdProjectsAmount: newCreatedProjectsAmount }));
                    }
                    if (props.createdProductsAmount) {
                        set(() => ({ isCustomerProductsAmountUpdating: true }));
                        const newCreatedProductsAmount = get().createdProductsAmount + props.createdProductsAmount;
                        updatedLimitationData = { ...updatedLimitationData, createdCustomProductsInLimitPeriod: newCreatedProductsAmount };
                        set(() => ({ createdProductsAmount: newCreatedProductsAmount }));
                    }
                    if (props.createdStandardProductsAmount) {
                        set(() => ({ isCustomerProductsAmountUpdating: true }));
                        const newCreatedStandardProductsAmount = get().createdStandardProductsAmount + props.createdStandardProductsAmount;
                        updatedLimitationData = {
                            ...updatedLimitationData,
                            createdStandardProductsInLimitPeriod: newCreatedStandardProductsAmount,
                        };
                        sessionStorage.setItem(
                            SessionStorageItemType.CREATED_STANDARD_PRODUCTS_AMOUNT,
                            String(newCreatedStandardProductsAmount),
                        );
                        set(() => ({ createdStandardProductsAmount: newCreatedStandardProductsAmount }));
                    }
                    if (props.downloadedImages) {
                        const newDownloadedImagesAmount = get().downloadedImages + props.downloadedImages;
                        updatedLimitationData = { ...updatedLimitationData, downloadedImages: newDownloadedImagesAmount };
                        set(() => ({ downloadedImages: newDownloadedImagesAmount }));
                    }
                    if (props.downloadedVideos) {
                        const newDownloadedVideosAmount = get().downloadedVideos + props.downloadedVideos;
                        updatedLimitationData = { ...updatedLimitationData, downloadedVideos: newDownloadedVideosAmount };
                        set(() => ({ downloadedVideos: newDownloadedVideosAmount }));
                    }
                    if (props.generatedPacks) {
                        const newGeneratedPacksAmount = get().generatedPacks + props.generatedPacks;
                        updatedLimitationData = { ...updatedLimitationData, generatedPacks: newGeneratedPacksAmount };
                        set(() => ({ generatedPacks: newGeneratedPacksAmount }));
                    }
                    const updatedUser = await dataOperations.updateUserLimitationData(props.user, updatedLimitationData);
                    set(() => ({ limitation: updatedLimitationData, isCustomerProductsAmountUpdating: false }));

                    return updatedUser;
                } catch (error) {
                    console.log('Failed to update stripe customer', error);
                }
            },
            setLimitation: async (props: { user: CognitoUser; limitation: LimitationType }) => {
                set(() => ({ isLimitationSet: false }));
                try {
                    if (props.limitation && props.limitation.limitResetDate && props.limitation.storiesLimitPeriod) {
                        if (!Object.keys(get().stripeCustomer).length) {
                            set(() => ({
                                generatedStoriesAmount: props.limitation.generatedStoriesInLimitPeriod as number,
                                generatedReimaginesAmount: props.limitation.generatedReimagines
                                    ? (props.limitation.generatedReimagines as number)
                                    : 0,
                                createdProjectsAmount: props.limitation.createdProjectsInLimitPeriod as number,
                                createdProductsAmount: props.limitation.createdCustomProductsInLimitPeriod as number,
                                createdStandardProductsAmount: props.limitation.createdStandardProductsInLimitPeriod as number,
                                downloadedImages: props.limitation.downloadedImages as number,
                                downloadedVideos: props.limitation.downloadedVideos as number,
                                generatedPacks: props.limitation.generatedPacks as number,
                                limitation: defaultValues.limitation,
                            }));
                        } else {
                            const currentDate = new Date().valueOf();
                            const isLimitPeriodPast = currentDate > Number(props.limitation.limitResetDate);
                            if (isLimitPeriodPast) {
                                const generatedStoriesAmount = 0;
                                const downloadedImagesAmount = 0;
                                const downloadedVideosAmount = 0;
                                const generatedPacksAmount = 0;
                                const amountOfLimitPeriods = Math.ceil(
                                    (currentDate - Number(props.limitation.limitResetDate)) / Number(props.limitation.storiesLimitPeriod),
                                );
                                const newLimitResetDate =
                                    amountOfLimitPeriods * Number(props.limitation.storiesLimitPeriod) +
                                    Number(props.limitation.limitResetDate);
                                const updatedLimitationData = {
                                    ...props.limitation,
                                    limitResetDate: JSON.stringify(newLimitResetDate),
                                    generatedStoriesInLimitPeriod: generatedStoriesAmount,
                                    downloadedImages: downloadedImagesAmount,
                                    downloadedVideos: downloadedVideosAmount,
                                    generatedPacks: generatedPacksAmount,
                                };
                                const updatedUserLimitation = await dataOperations.updateUserLimitationData(
                                    props.user,
                                    updatedLimitationData,
                                );
                                set(() => ({
                                    generatedStoriesAmount,
                                    generatedReimaginesAmount: props.limitation.generatedReimagines
                                        ? (props.limitation.generatedReimagines as number)
                                        : 0,
                                    createdProjectsAmount: props.limitation.createdProjectsInLimitPeriod as number,
                                    createdProductsAmount: props.limitation.createdCustomProductsInLimitPeriod as number,
                                    createdStandardProductsAmount: props.limitation.createdStandardProductsInLimitPeriod as number,
                                    downloadedImages: downloadedImagesAmount,
                                    downloadedVideos: downloadedVideosAmount,
                                    generatedPacks: generatedPacksAmount,
                                    limitation: updatedLimitationData,
                                }));
                            } else {
                                set(() => ({
                                    generatedStoriesAmount: props.limitation.generatedStoriesInLimitPeriod as number,
                                    generatedReimaginesAmount: props.limitation.generatedReimagines
                                        ? (props.limitation.generatedReimagines as number)
                                        : 0,
                                    createdProjectsAmount: props.limitation.createdProjectsInLimitPeriod as number,
                                    createdProductsAmount: props.limitation.createdCustomProductsInLimitPeriod as number,
                                    createdStandardProductsAmount: props.limitation.createdStandardProductsInLimitPeriod as number,
                                    downloadedImages: props.limitation.downloadedImages as number,
                                    downloadedVideos: props.limitation.downloadedVideos as number,
                                    generatedPacks: props.limitation.generatedPacks as number,
                                    limitation: props.limitation,
                                }));
                            }
                        }
                    }
                    set(() => ({ isLimitationSet: true }));
                } catch (error) {
                    console.log('Failed to set limitation to user', error);
                }
            },
            setCustomerSubscriptionFetched: () => {
                set(() => ({ isCustomerSubscriptionFetched: true }));
            },
            setSubscriptionCanceledState: async (props: { user: CognitoUser; state: boolean }) => {
                try {
                    await dataOperations.updateUserSubscriptionCanceledState(props.user, props.state);
                } catch (error) {
                    console.log('Failed to set subscription canceled state to user', error);
                }
            },
        }),
        {
            name: 'subscription-store',
        },
    ),
);

export default useSubscriptionStore;
